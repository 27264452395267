import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import {
  mobile,
  mobileLarge,
  mobileMedium,
  tablet,
} from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  GAME_MODES,
  HEROES_TO_ARTICLE_ID,
  PLATFORMS,
  ROLES,
  SEARCH_PARAMS,
} from "@/game-marvel-rivals/constants.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import type { Tierlist } from "@/game-marvel-rivals/models/tierlist.mjs";
import {
  SelectGameMode,
  SelectPlatform,
  SelectRole,
} from "@/game-marvel-rivals/Selects.jsx";
import Container from "@/shared/ContentContainer.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const REGEXP_NUMBER = /\d+(?:\.\d+)?/;

export default function TierlistPage() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const { platform, mode, role } = useMemo(
    () => ({
      platform: searchParams.get(SEARCH_PARAMS.PLATFORM) || PLATFORMS.PC,
      mode: searchParams.get(SEARCH_PARAMS.GAMEMODE) || GAME_MODES.QUICKPLAY,
      role: searchParams.get(SEARCH_PARAMS.ROLE) || ROLES.ALL,
    }),
    [searchParams],
  );
  const {
    marvelrivals: { tierlist },
  } = useSnapshot(readState);
  const heroes = useMemo(() => {
    const roles = Object.entries(tierlist[platform] ?? {}).find((i) =>
      i[0]?.toLowerCase().includes(mode),
    );
    if (!roles) return [];
    for (const r in ROLES) {
      if (!role.includes(ROLES[r])) continue;
      // Get all heroes within a role
      return getHeroes(roles[1], ROLES[r]);
    }
    // Default to retrieving all heroes in all roles
    return getHeroes(roles[1], ROLES.ALL);
  }, [mode, platform, role, tierlist]);
  const tiers = useMemo<Array<Array<Tierlist[string]["Defender"][string]>>>(
    () =>
      heroes
        .reduce((acc, cur) => {
          if (acc[cur.tier]) {
            acc[cur.tier].push(cur);
          } else {
            acc[cur.tier] = [cur];
          }
          return acc;
        }, [])
        .filter(Boolean),
    [heroes],
  );

  if (!tiers.length) {
    return (
      <Container className="flex column gap-4">
        <PageHeader
          image={`${appURLs.CDN}/blitz/marvelrivals/marvel-rivals-logo-colored.webp`}
          title={t(
            "marvelrivals:title.marvelRivalsHeroTierlist",
            "Marvel Rivals Hero Tierlist and Stats",
          )}
        />
        <FilterBar hiddenItems={2}>
          <SelectGameMode />
          <SelectPlatform />
          <SelectRole />
        </FilterBar>
        <Card loading style={{ height: 848 }} />
      </Container>
    );
  }

  return (
    <Container className="flex column gap-4">
      <PageHeader
        image={`${appURLs.CDN}/blitz/marvelrivals/marvel-rivals-logo-colored.webp`}
        title={t(
          "marvelrivals:title.marvelRivalsHeroTierlist",
          "Marvel Rivals Hero Tierlist and Stats",
        )}
      />
      <FilterBar hiddenItems={2}>
        <SelectGameMode />
        <SelectPlatform />
        <SelectRole />
      </FilterBar>
      <Card className="flex column gap-10">
        {tiers.map((i, idx) => {
          const iconUrl = getTierIcon(idx + 1);
          return (
            <div key={iconUrl} className={cssGrid()}>
              <img src={iconUrl} width="40" height="40" />
              <div className="hero-grid">
                {i.map((j) => {
                  return (
                    <a
                      key={j.id}
                      className="flex align-center gap-2"
                      href={
                        HEROES_TO_ARTICLE_ID[j.hero]
                          ? `/${GAME_SHORT_NAMES[GAME_SYMBOL_MARVEL_RIVALS]}/article/${HEROES_TO_ARTICLE_ID[j.hero]}`
                          : undefined
                      }
                    >
                      <img
                        src={`${appURLs.CDN_PLAIN}/blitz/marvelrivals/${j.id}.webp`}
                        alt={j.hero}
                        width={64}
                        height={64}
                      />
                      <div>
                        <div className="name type-title--bold">{j.hero}</div>
                        <div className="shade2 type-callout--bold">
                          {t(
                            "common:percentWinrate",
                            "{{winRate, percent}} Win Rate",
                            {
                              winRate: j.winRate,
                            },
                          )}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Card>
    </Container>
  );
}

function getHeroes(
  roles: Tierlist[string],
  key: (typeof ROLES)[keyof typeof ROLES],
) {
  const heroes: Array<Tierlist[string]["Defender"][string]> = [];
  // All roles
  if (key === ROLES.ALL) {
    for (const k in roles) {
      for (const h in roles[k]) {
        const hero = roles[k][h];
        heroes.push(hero);
      }
    }
  } else {
    // Singular role
    const k = Object.keys(roles).find((i) => i.toLowerCase() === key);
    if (k) {
      for (const h in roles[k]) {
        const hero = roles[k][h];
        heroes.push(hero);
      }
    }
  }
  return heroes.map((prev) => {
    // @ts-ignore - We're transforming the strings to numbers
    const next: {
      hero: string;
      pickRate: number;
      winRate: number;
      id: string;
      tier: number;
    } = {
      ...prev,
    };
    next.pickRate = sanitizeNumber(
      Number(prev.pickRate.match(REGEXP_NUMBER)?.[0]) / 100,
    );
    next.winRate = sanitizeNumber(
      Number(prev.winRate.match(REGEXP_NUMBER)?.[0]) / 100,
    );
    return next;
  });
}

export function meta() {
  return {
    title: [
      "marvelrivals:meta.tierlist.title",
      "Marvel Rivals Tier List: Best Heroes Ranked (Meta Update) – Blitz Marvel Rivals",
    ],
    description: [
      "marvelrivals:meta.tierlist.description",
      "Find the best heroes in Marvel Rivals with our updated tier list! See which heroes dominate the meta, ranked from strongest to weakest, with insights on their performance, strengths, and competitive viability.",
    ],
    subtitle: true,
  };
}

const cssGrid = () =>
  css({
    display: "flex",
    alignItems: "flex-start",
    gap: "var(--sp-4)",
    [mobileLarge]: {
      flexDirection: "column",
    },
    ".hero-grid": {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "var(--sp-4)",
      img: {
        flexShrink: 0,
        height: "64px",
        width: "64px",
      },
      [tablet]: {
        gridTemplateColumns: "repeat(3, 1fr)",
      },
      [mobile]: {
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "var(--sp-2)",
      },
      [mobileMedium]: {
        gridTemplateColumns: "1fr",
      },
      ".name": {
        maxWidth: "13ch",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  });
